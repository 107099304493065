import React from 'react';




export default function Contact() {
  return (
    <div class="page page--contact">
      <div class="intro intro--flow">
        <h1 class="intro__title">lets<br />chat.</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
      </div>
      <div class="form">
        <div class="form__content">
          <h2>Drop me a line.</h2>
          <p>Loremi ipsum dolor sit amet dolor istum vigor dolor istum vigor alet meeem</p>
          <form>
            <fieldset>
              <label>
                Name*
                <input name="fname" />
              </label>
              <label>
                Email*
                <input name="email" type="email" />
              </label>
              <label>
                Phone number
                <input name="email" type="number" />
              </label>
            </fieldset>
            <label>
              Message*
              <textarea name="comments"></textarea>
            </label>
            <button class="button button--full" type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}
