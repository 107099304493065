export default {
	"success": true,
	"data": {
			"clients": [
					{
							"id": "1",
							"name": "Disney",
							"logo": "https://russjkirby.com/images/logos/disney.png"
					},
					{
						"id": "2",
						"name": "Manchester United",
						"logo": "https://russjkirby.com/images/logos/man-u.png"
					},
					{
						"id": "3",
						"name": "R&A",
						"logo": "https://russjkirby.com/images/logos/r&a.png"
					},
					{
						"id": "4",
						"name": "Sony",
						"logo": "https://russjkirby.com/images/logos/sony.png"
					},
					{
						"id": "5",
						"name": "NHS",
						"logo": "https://russjkirby.com/images/logos/nhs.png"
					},
					{
						"id": "6",
						"name": "Lewis Hamilton",
						"logo": "https://russjkirby.com/images/logos/hamilton.png"
					},
					{
						"id": "7",
						"name": "Euro Tunnel",
						"logo": "https://russjkirby.com/images/logos/euro-tunnel.png"
					},
					{
						"id": "8",
						"name": "The Open",
						"logo": "https://russjkirby.com/images/logos/the-open.png"
					},
					{
						"id": "9",
						"name": "Wargaming",
						"logo": "https://russjkirby.com/images/logos/wargaming.png"
					},
					{
						"id": "10",
						"name": "Fairtrade",
						"logo": "https://russjkirby.com/images/logos/fairtrade.png"
					},
					{
						"id": "11",
						"name": "D-Link",
						"logo": "https://russjkirby.com/images/logos/d-link.png"
					},
					{
						"id": "12",
						"name": "Chelsea",
						"logo": "https://russjkirby.com/images/logos/chelsea.png"
					},
					{
						"id": "13",
						"name": "Aviva",
						"logo": "https://russjkirby.com/images/logos/aviva.png"
					},
					{
						"id": "14",
						"name": "Ferrari World",
						"logo": "https://russjkirby.com/images/logos/ferrari-world.png"
					},
					{
						"id": "15",
						"name": "Flydubai",
						"logo": "https://russjkirby.com/images/logos/flydubai.png"
					},
					{
						"id": "16",
						"name": "Jumbo Gamesy",
						"logo": "https://russjkirby.com/images/logos/jumbo-games.png"
					},
					{
						"id": "17",
						"name": "RBS",
						"logo": "https://russjkirby.com/images/logos/rbs.png"
					},
					{
						"id": "18",
						"name": "Paddy Power",
						"logo": "https://russjkirby.com/images/logos/paddy-power.png"
					}
			]
	}
}