import React from 'react';

// Assets
import sky from '../assets/images/sky.webp';
import scene from '../assets/images/scene.webp';

export default function Home() {
  return (
    <div className="page page--home">
      <div className="page__bg">
        <div className="bg bg--sky" style={{backgroundImage: "url(" + sky + ")"}}></div>
        <div className="bg bg--land" style={{backgroundImage: "url(" + scene + ")"}}></div>
      </div> 
      <div className="intro">
        <h1 className=" glitch" data-text="hi.">hi.</h1>
        <p className="intro__text">My name is Russ Kirby. I have been a Front End Developer for over 16 years building websites and apps for both big and small companies all over the world. </p>
      </div>
    </div>
  );
}
