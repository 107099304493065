import React from "react"

import { Waypoint } from 'react-waypoint';

import webbyImg from '../assets/images/projects/webby-nominee.png';

export default function Project(props) {
	const webby = props.webby;

	const [visible, setVisible] = React.useState(false)

  function enter() {
		setVisible(prevState => true)
	}

	function leave() {
		setVisible(prevState => false)
	}

	return (
		<Waypoint bottomOffset="90%" onEnter={enter}  onLeave={leave}>
			<article className={"project project--" + props.class + (visible ? ' project--active' : "")}>
				<div className="project__bg" style={{backgroundImage: `url(${props.bg})` }}></div>
				<div className="project__text">
					{ webby ? <img className="webby-badge" src={webbyImg} alt="Webby nominated" /> : '' }
					<h3>{props.name}</h3>
					<span className="project__meta">{props.meta}</span>
					<p>{props.desc}</p>
					<a href={props.url} target="_blank" rel="noreferrer" className="button">{props.buttonText}</a>
				</div>
			</article>
		</Waypoint>
	)
}
	
