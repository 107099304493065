export default {
	"success": true,
	"data": {
			"projects": [
					{
							"id": "1",
							"class": "bt",
							"name": "BT Marketing Hub",
							"meta": "2021 at Jellyfish",
							"desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
							"bg": "https://russjkirby.com/images/projects/bt.webp",
							"screens": null,
							"buttonText": "Visit Hub",
							"url": "https://marketing.business.bt.com/login/welcome",
							"webby": false
					},
					{
							"id": "2",
							"class": "simmons",
							"name": "Simmons & Simmons",
							"meta": "2021 at Jellyfish",
							"desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
							"bg": "https://russjkirby.com/images/projects/simmons.webp",
							"screens": null,
							"buttonText": "Visit Site",
							"url": "https://www.simmons-simmons.com/",
							"webby": true
					},
					{
							"id": "3",
							"class": "aviva",
							"name": "Aviva Investors",
							"meta": "2021 at Jellyfish",
							"desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
							"bg": "https://russjkirby.com/images/projects/aviva.webp",
							"screens": null,
							"buttonText": "Visit Site",
							"url": "https://www.avivainvestors.com/en-gb/",
							"webby": false
					},
					{
							"id": "4",
							"class": "universal",
							"name": "Universal Publishing",
							"meta": "2021 at Jellyfish",
							"desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
							"bg": "https://russjkirby.com/images/projects/universal.webp",
							"screens": null,
							"buttonText": "Visit Site",
							"url": "https://www.umusicpub.com/uk/",
							"webby": false
					},
					{
							"id": "5",
							"class": "rooney",
							"name": "Wayne Rooney",
							"meta": "2021 at Jellyfish",
							"desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
							"bg": "https://russjkirby.com/images/projects/rooney.webp",
							"screens": [
								{
										"id": "1",
										"name": "Drake Hotline Bling",
										"src": "https://i.imgflip.com/30b1gx.jpg"
								},
								{
									"id": "2",
									"name": "Drake Hotline Bling",
									"src": "https://i.imgflip.com/30b1gx.jpg"
								},
							],
							"buttonText": "Screenshots",
							"url": "https://marketing.business.bt.com/login/welcome",
							"webby": false
					},
					{
							"id": "6",
							"class": "ea",
							"name": "Electronic Arts",
							"meta": "2021 at Jellyfish",
							"desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
							"bg": "https://russjkirby.com/images/projects/electronic-arts.webp",
							"screens": [
								{
										"id": "1",
										"name": "Drake Hotline Bling",
										"src": "https://i.imgflip.com/30b1gx.jpg"
								},
								{
									"id": "2",
									"name": "Drake Hotline Bling",
									"src": "https://i.imgflip.com/30b1gx.jpg"
								},
							],
							"buttonText": "Screenshots",
							"url": "https://marketing.business.bt.com/login/welcome",
							"webby": false
					}
			]
	}
}