import React from "react"

export default function Client(props) {
	return (
		<li className="client-list__item">
			<img src={props.logo} alt={props.name} />
		</li>
	)
}
	
