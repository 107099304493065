import React from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Header from'./components/Header';
import Footer from'./components/Footer';
import Home from'./components/Home';
import Work from'./components/Work';
import Contact from'./components/Contact';

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
   
      <TransitionGroup>
        <CSSTransition key={location.key} timeout={500} classNames="fade">
          <div className="page-wrapper">
            <Routes location={location}>
              <Route index element={<Home />} />
              <Route path="/work" element={<Work />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </CSSTransition>
      </TransitionGroup>
   
    
  );
};

function App() {
  return (
    <div className="App">
       <div className="frame">
        <BrowserRouter>
          <Header />
          <AnimatedRoutes />
        </BrowserRouter>
        <Footer />
       </div>
    </div>
  );
}

export default App;
